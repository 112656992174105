/* @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap') */
/* 
@font-face {
    font-family: "Lato";
    src: url("https://fonts.googleapis.com/css2?family=Lato") format("woff2");
} */

body {
  font-family: 'sans-serif';
}
